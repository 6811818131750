







import Vue from 'vue';
import { getLegacyAdminUrl } from '@/env';

export default Vue.extend({
  name: 'FripNotice',
  components: {},
  data() {
    return {
      noticeType: this.$route.params.noticeType,
      menuName:
        this.$route.params.noticeType === 'host'
          ? '호스트 공지사항'
          : '공지사항',
    };
  },
  created: function () {
    const legacyAdminUri =
      this.noticeType === 'host' ? '/host-notice' : '/notice';
    const path = getLegacyAdminUrl() + legacyAdminUri;
    window.open(path);
  },
});
